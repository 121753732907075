import { useEffect, useState } from 'react';

const useWindowWidth = (config?: { ssr?: boolean }) => {
  const [width, setWidth] = useState<null | number>(
    // eslint-disable-next-line no-nested-ternary
    config?.ssr
      ? null
      : typeof window !== 'undefined'
      ? window.innerWidth
      : null,
  );

  useEffect(() => {
    setWidth(window.innerWidth);

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width;
};

export default useWindowWidth;
